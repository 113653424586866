import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import type { AppServiceState } from "../redux/slices/AppServiceSlice";
import { type TranslationState, withAppSettigs } from "../HOC/withAppSettings";
import { withCameraPermission } from "../HOC/withCameraPermission";
import { deviceType } from "../hooks/useDeviceType";
import { MobileSupportOnly, SplashPage } from "./SplashPage";
import LogoHeader from "../components/common/LogoHeader";

const Layout = ({
    settingLoading,
    translationLoading,
    translations,
    appSettings,
    translation,
}: AppServiceState & TranslationState) => {
    const [label, setLabel] = useState<string>("Loader...");
    const [loading, setLoading] = useState<boolean>(true);
    const [showLandscapePrevented, setLandscapePrevented] =
        useState<boolean>(true);

    const { isMobile } = deviceType();
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (settingLoading) {
            setLabel(t("loading.loading_settings"));
        } else if (translationLoading) {
            setLabel(t("loading.loading_translation"));
        }

        const isSettingLoading = settingLoading ?? true;
        const isTransLoading = translationLoading ?? true;
        const isLoading = isSettingLoading || isTransLoading;

        setLoading(isLoading);
    }, [settingLoading, translationLoading]);

    const NoCameraComponent = (
        <SplashPage
            isAnimating={true}
            label={label}
            color={appSettings?.button_text_color ?? "#fff"}
            logo={appSettings?.main_logo}
        />
    );

    return (
        <>
            <div
                id={`landscape-screen`}
                className={`${showLandscapePrevented ? "active" : "inactive"} `}
            >
                <h3>Please rotate your device</h3>
                <p>Please go back to portrait mode for the best experience.</p>
            </div>

            {loading ? (
                NoCameraComponent
            ) : (
                <div className="main-wrapper">
                    <LogoHeader />
                    <Outlet
                        context={{
                            settingLoading,
                            translationLoading,
                            translations,
                            appSettings,
                            translation,
                        }}
                    />
                </div>
            )}
        </>
    );
};

const LayoutWithPermission = withCameraPermission(Layout);
export default withAppSettigs(LayoutWithPermission);
