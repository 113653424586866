import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../redux/store'
import type { TranslationState } from './withAppSettings'
import type { AppServiceState } from '../redux/slices/AppServiceSlice'
import { FieldState, getFields } from '../redux/slices/FieldSlice'

/**
 * HOC that adds an `isVisible` prop that stops a component from rendering if
 * `isVisible===false`
 * @param WrappedComponent component to be selectively hidden
 * @returns null if `isVisible` is false
 */
export function withFields<P>(WrappedComponent: React.ComponentType<P>) {

  const AppServiceControlled = (
    props: P & TranslationState & AppServiceState,
  ) => {
    
    const dispatch = useAppDispatch()
   const { fields, loading } = useAppSelector((state) => state.fieldReducer)
    const appSettingState = useAppSelector((state) => state.appSettingsReducer)

    useEffect(() => {
      if (
        appSettingState.appSettings?.app_registration_required == '1' &&
        fields?.length == 0 &&
        loading == null
      ) {
        dispatch(getFields())
      }
    }, [dispatch, appSettingState, fields, loading])

    return (
      <WrappedComponent
        {...props}
        fieldState={{ fields } as FieldState}
        app_registration_required={appSettingState?.appSettings?.app_registration_required}
      />
    )
  }

  return AppServiceControlled
}
