import 'reflect-metadata'
import { Container } from 'inversify'
import { AppServiceImpl } from '../services/AppServiceImpl'
import { ArtistServiceImpl } from '../services/ArtistServiceImpl'
import { TeamServiceImpl } from '../services/TeamServiceImpl'
import { MockAppService } from '../services/MockAppService'
import { MockArtistService } from '../services/MockArtistService'
import { MockTeamService } from '../services/MockTeamService'
import { MockUserService } from '../services/MockUserService'
import { UserServiceImpl } from '../services/UserServiceImpl'
import { MockFieldService } from '../services/MockFieldService'
import { ArtistStorage } from './ArtistStorage'
import { PROFILE } from './Profile'
import { TYPES } from './types'
import type { ArtistModel } from '../HOC/withArtists'
import type { AppService } from '../services/AppService'
import type { ArtistService } from '../services/ArtistService'
import type { UserService } from '../services/UserService'
import { TeamService } from '../services/TeamService'
import { FieldServiceInterface } from '../interface/FieldServiceInterface'
import { FilterServiceInterface } from '../interface/FilterServiceInterface'
import { FieldService } from '../services/FieldService'
import { FilterService } from '../services/FilterService'
import { MockFilterService } from '../services/mock/MockFilterService'

const Profile = import.meta.env.VITE_APP_PROFILE
const AppContainer = new Container()
AppContainer.bind<ArtistModel>(TYPES.ArtistStorage).to(ArtistStorage).inSingletonScope()

if (Profile === PROFILE.mock) {
  AppContainer.bind<AppService>(TYPES.AppService).to(MockAppService).inSingletonScope()
  AppContainer.bind<ArtistService>(TYPES.ArtistService).to(MockArtistService).inSingletonScope()
  AppContainer.bind<UserService>(TYPES.UserService).to(MockUserService).inSingletonScope()
  AppContainer.bind<TeamService>(TYPES.TeamService).to(MockTeamService).inSingletonScope()
  AppContainer.bind<FieldServiceInterface>(TYPES.FieldServiceInterface).to(MockFieldService).inSingletonScope()
  AppContainer.bind<FilterServiceInterface>(TYPES.FilterServiceInterface).to(MockFilterService).inSingletonScope()
  
} else if (Profile === PROFILE.dev) {
  AppContainer.bind<AppService>(TYPES.AppService).to(AppServiceImpl).inSingletonScope()
  AppContainer.bind<ArtistService>(TYPES.ArtistService).to(ArtistServiceImpl).inSingletonScope()
  AppContainer.bind<UserService>(TYPES.UserService).to(UserServiceImpl).inSingletonScope()
  AppContainer.bind<TeamService>(TYPES.TeamService).to(TeamServiceImpl).inSingletonScope()
  AppContainer.bind<FieldServiceInterface>(TYPES.FieldServiceInterface).to(FieldService).inSingletonScope()
  AppContainer.bind<FilterServiceInterface>(TYPES.FilterServiceInterface).to(FilterService).inSingletonScope()
} else {
  throw new Error('Please add Profile In .env ' + Profile)
}

export { AppContainer }
