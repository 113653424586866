import React, { FormHTMLAttributes, useRef, useState } from "react";
import { Field } from "../../interface/FieldServiceInterface";
import { Utils } from "../../utils/Utils";
import { useTranslation } from "react-i18next";
import { StringKeyValueContainer } from "../../models/StringKeyValueContainer";
import { PageRoutes } from "../../models/RootEnum";
import { useNavigate } from "react-router-dom";
import { Locals } from "../../helpers/Storage";
import { useAppSettings } from "../../hooks/useAppSettings";
import Modal from "react-bootstrap/Modal";
import { RegisterApi } from "../../Apis/PROXClient";
import { Spinner } from "../common/Spinner";
import countries from "../../mock-data/countries.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export const azertyLayout = {
    default: [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{tab} a z e r t y u i o p [ ] \\',
        '{lock} q s d f g h j k l m ù {enter}',
        '{shift} w x c v b n . ; : ! {shift}',
        '.com @ {space}'
    ]
};

const Fields = ({
    fields,
    handleSubmitForm,
}: {
    fields: Array<Field>;
    handleSubmitForm: () => void;
}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const app_settings = useAppSettings();
    const currentLocale = i18n.language;

    const [fieldValues, setFieldValues] = useState<StringKeyValueContainer>({
        mobile_number_country_code: "+32",
    });
    const [errors, setErrors] = useState<StringKeyValueContainer>();

    const [saving, setSaving] = useState<boolean>(false);

    const handleChange = (e: any) => {
        const { name, value, type } = e.target;

        if (errors?.[name]) {
            setErrors((prev) => {
                delete prev[name];
                return { ...prev };
            });
        }

        setFieldValues((prev) => {
            return {
                ...prev,
                [name]: type === "checkbox" ? e.target.checked : value,
            };
        });
    };

    const app_local_settings = localStorage.getItem("AppSettings");
    const LocalSettings = JSON.parse(app_local_settings);
    const [appSettings, setAppSettings] = useState(LocalSettings);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setSaving(true);
        const payload = {
            locale: currentLocale,
            fields: fieldValues,
            email: fieldValues?.email,
        };

        RegisterApi.register(payload)
            .then((res) => {
                const mail_recipient = res?.data;
                let registration_expire_after =
                    Number(
                        app_settings.appSettings?.registration_expire_after
                    ) ?? 48;
                let expire_at =
                    new Date().getTime() +
                    registration_expire_after * 60 * 60 * 1000;

                const stringifyForm = JSON.stringify(mail_recipient);
                localStorage.setItem(
                    Locals.MAIL_RECIPIENT_ID,
                    mail_recipient?.id
                );
                localStorage.setItem(Locals.REGISTRATION_DATA, stringifyForm);
                localStorage.setItem(
                    Locals.REGISTRATION_DATA_EXPIRE_AT,
                    expire_at.toString()
                );

                setSaving(false);
                navigate(PageRoutes.FlipGamePage);
                handleSubmitForm();
            })
            .catch((err) => {
                console.log(err);
                setSaving(false);

                if (err?.response?.status === 422) {
                    const tmpErrors = err?.response?.data?.errors;
                    var errs = { ...errors };
                    Object.keys(tmpErrors).forEach((key) => {
                        if (key.startsWith("fields.")) {
                            errs[key.replace("fields.", "")] =
                                tmpErrors[key][0];
                        } else {
                            errs[key] = tmpErrors[key][0];
                        }
                    });

                    setErrors(errs);
                }
            });
    };

    const getColNumber = (field_slug) => {
        if (field_slug === "city") {
            return "col-8 pe-0";
        } else if (field_slug === "gender") {
            return "col-4";
        } else {
            return "col-12";
        }
    };

    const [focusInput, setFocusInput] = useState(null);
    const onFocus = (e) => {
        const { name, value, type } = e.target;
        setFocusInput ({ name, value, type });
        keyboard.current.setInput(fieldValues[name]);
    }

    const [input, setInput] = useState("");
    const [layout, setLayout] = useState("default");
    const keyboard = useRef();

    const onChange = input => {
        if (focusInput){
            const { name, value, type } = focusInput;
            let _value = input;

            if (type === 'number'){
                 _value = _value.replace(/[^0-9]/g, '');
            }
            console.info({ name, value, type, _value })
            setFieldValues((prev) => ({
                ...prev,
                [name]: _value,
            }));
        }

    };

    const handleShift = () => {
        const newLayoutName = layout === "default" ? "shift" : "default";
        setLayout(newLayoutName);
    };

    const onKeyPress = button => {
        console.log("Button pressed", button);
        /**
         * If you want to handle the shift and caps lock buttons
         */
        if (button === "{shift}" || button === "{lock}") handleShift();
    };


    const renderFields = fields?.map((field: Field) => {
        return (
            <div
                className={`field-item ${getColNumber(field?.slug)}`}
                key={`field_${field?.id}`}
            >
                {["text", "email", "number"].includes(
                    field?.field_type ?? ""
                ) && (
                    <div className="form-group">
                        <input
                            type={field?.field_type}
                            className={`form-control input-box ${
                                errors?.[field?.slug] ? "is-invalid" : ""
                            }`}
                            placeholder={
                                Utils.getFieldName(
                                    field?.name ?? "",
                                    currentLocale
                                ) + (field?.required ? "*" : "")
                            }
                            name={field?.slug?.toString()}
                            required={field?.required ?? false}
                            onChange={handleChange}
                            onFocus={onFocus}
                            minLength={field?.meta_attributes?.min ?? 0}
                            maxLength={field?.meta_attributes?.max ?? 0}
                            value={fieldValues?.[field?.slug?.toString()] ?? ""}
                        />
                        {errors?.[field?.slug] &&
                        fieldValues?.[field?.slug?.toString()]?.length > 0 ? (
                            <p className="text-danger pt-0">
                                {errors?.[field?.slug]}
                            </p>
                        ) : (
                            ""
                        )}
                    </div>
                )}

                {field?.field_type === "date" && (
                    <div className="form-group">
                        <input
                            type={`date`}
                            className={`form-control input-box ${
                                errors?.[field?.slug] ? "is-invalid" : ""
                            } `}
                            placeholder={Utils.getFieldName(
                                field?.name ?? "",
                                currentLocale
                            )}
                            name={field?.slug?.toString()}
                            onFocus={onFocus}
                            required={field?.required ?? false}
                            onChange={handleChange}
                            value={fieldValues?.[field?.slug?.toString()] ?? ""}
                        />
                        {errors?.[field?.slug] &&
                        fieldValues?.[field?.slug?.toString()]?.length > 0 ? (
                            <p className="text-danger pt-0">
                                {errors?.[field?.slug]}
                            </p>
                        ) : (
                            ""
                        )}
                    </div>
                )}

                {field?.field_type === "radio" &&
                    (field?.field_options?.length ?? 0) > 0 && (
                        <div className="form-group gender-sec">
                            {/* <label>
                                {Utils.getFieldName(
                                    field?.name ?? "",
                                    currentLocale
                                )}
                            </label> */}
                            {field?.field_options?.map((fo, fo_index) => {
                                return (
                                    <div
                                        className="form-check "
                                        key={field?.id + "_" + fo?.id}
                                    >
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            required={field?.required ?? false}
                                            name={field?.slug?.toString()}
                                            id={fo?.id + field?.id?.toString()}
                                            value={Utils.getFieldName(
                                                fo?.value ?? "",
                                                currentLocale
                                            )}
                                            onChange={handleChange}
                                            checked={
                                                fieldValues?.[
                                                    field?.slug?.toString()
                                                ] ==
                                                Utils.getFieldName(
                                                    fo?.value ?? "",
                                                    currentLocale
                                                )
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={
                                                fo?.id + field?.id?.toString()
                                            }
                                        >
                                            {Utils.getFieldName(
                                                fo?.value ?? "",
                                                currentLocale
                                            )}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                {field?.field_type === "checkbox" && (
                    <div className="form-group form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name={field?.slug?.toString()}
                            id={field?.id?.toString()}
                            value="1"
                            onChange={handleChange}
                            required={field?.required ?? false}
                            checked={
                                fieldValues?.[field?.slug?.toString()] == true
                                    ? true
                                    : false
                            }
                        />
                        <label
                            className="form-check-label"
                            htmlFor={field?.id?.toString() ?? ""}
                        >
                            {Utils.getFieldName(
                                field?.placeholder ?? "",
                                currentLocale
                            )}
                        </label>

                        {field?.slug === "accept_mail_newsletter" && (
                            <a
                                href="#"
                                style={{ textDecoration: "underline" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                {/* {t("general.more_info")} */}
                            </a>
                        )}
                    </div>
                )}

                {field?.field_type === "dropdown" &&
                    (field?.field_options?.length ?? 0) > 0 && (
                        <div className="form-group position-relative">
                            <select
                                className={`form-control input-box ${
                                    errors?.[field?.slug] ? "is-invalid" : ""
                                } `}
                                defaultValue={"+32"}
                                onChange={handleChange}
                                required={field?.required ?? false}
                                name={field?.slug?.toString()}
                            >
                                {/* <option value="" disabled>{Utils.getFieldName(field?.name ?? '', currentLocale)}</option> */}
                                {field?.slug === "mobile_number_country_code"
                                    ? countries.map((cntr) => {
                                          return (
                                              <option
                                                  key={cntr.code}
                                                  value={cntr.dial_code}
                                              >
                                                  {cntr.code} ({cntr.dial_code})
                                              </option>
                                          );
                                      })
                                    : field?.field_options?.map((fo) => {
                                          return (
                                              <option
                                                  key={field?.id + "_" + fo?.id}
                                                  value={Utils.getFieldName(
                                                      fo?.value ?? "",
                                                      currentLocale
                                                  )}
                                              >
                                                  {Utils.getFieldName(
                                                      fo?.value ?? "",
                                                      currentLocale
                                                  )}
                                              </option>
                                          );
                                      })}
                            </select>
                            {errors?.[field?.slug] &&
                            fieldValues?.[field?.slug?.toString()]?.length >
                                0 ? (
                                <p className="text-danger pt-0">
                                    {errors?.[field?.slug]}
                                </p>
                            ) : (
                                ""
                            )}

                            {/* <span className="select-arrow">
              <svg fill="none" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m6 9 6 6 6-6" strokeLinecap="round" strokeLinejoin="round" className="stroke-000000"></path>
              </svg>
            </span> */}
                        </div>
                    )}
            </div>
        );
    });

    return (
        <>
        <div className="fields-wrapper">
            {fields.length > 0 && (
                // <form onSubmit={handleSubmit} id="registration_form">
                //     <div className="row">
                //         {renderFields}

                //         <div className="form-group button-group">
                //             <div className="from-border">
                //                 <button
                //                     type="submit"
                //                     className="main-btn"
                //                     style={{ width: "100%" }}
                //                     disabled={saving}
                //                 >
                //                     {!saving ? (
                //                         t("registration.submit_button")
                //                     ) : (
                //                         <Spinner animating color="#fff" />
                //                     )}
                //                 </button>
                //             </div>
                //         </div>
                //     </div>
                // </form>
                <form
                    className="form-tag"
                    onSubmit={handleSubmit}
                    id="registration_form"
                    encType="multipart/form-data"
                >
                    <div className="head-div text-white">
                        <h5>{t('registration.title')}</h5>
                    </div>
                    {renderFields}

                    <div className="form-control-div text-end">
                            <div className="form-control-div text-end">
                                <button className="submit-btn position-relative">
                                    {!saving ? (
                                        t("registration.submit_button")
                                    ) : (
                                        <Spinner animating color="#fff" />
                                    )}
                                </button>
                                <span className="confirm-arrow">
                                    <svg
                                        viewBox="0 0 32 32"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M22 9a1 1 0 0 0 0 1.42l4.6 4.6H3.06a1 1 0 1 0 0 2h23.52L22 21.59A1 1 0 0 0 22 23a1 1 0 0 0 1.41 0l6.36-6.36a.88.88 0 0 0 0-1.27L23.42 9A1 1 0 0 0 22 9Z"
                                            data-name="Layer 2"
                                            fill="#ffffff"
                                            className="fill-000000"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                        
                    </div>
                    {appSettings?.enable_keyboard === "on" && (
                        // <div style={{ position: "fixed", bottom: 0, left: 0, right: 0}} className="key-board-wrapper">
                        <div className="mt-2">
                            <Keyboard
                                layout={azertyLayout}
                                keyboardRef={r => (keyboard.current = r)}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                            />
                        </div>
                    )}
                </form>
            )}
        </div>

        </>
    );
};

export default Fields;
