/* eslint-disable */
import { injectable } from 'inversify'
import { AppSettingApi } from '../Apis/PROXClient'
import type { AppService } from './AppService'
import type { AppSettings } from '../models/AppSettings'
import type { PageTranslations } from '../models/Translations'
import translations from '../mock-data/translations.json'

@injectable()
export class AppServiceImpl implements AppService {
  getAppSettings(): Promise<AppSettings> {
    return AppSettingApi.getSettings().then((data) =>  Promise.resolve(data.data!))
  }

  getTranslations(_translation_url: string): Promise<PageTranslations> {
    return new Promise<PageTranslations>((resolve) => {
      setTimeout(() => {
        resolve(translations)
      }, 500)
    })
  }
}
