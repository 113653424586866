import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppContainer } from '../../helpers/inversify.config'
import { TYPES } from '../../helpers/types'
import type { SNTLError } from './AppServiceSlice'
import { Field, FieldServiceInterface } from '../../interface/FieldServiceInterface'
import { FieldApi } from '../../Apis/PROXClient'

export interface FieldState {
  loading?: boolean | null
  fields: Array<Field>
  error?: SNTLError | null
}

const localFields = localStorage.getItem('local_fields');

const initialState: FieldState = {
  loading: null,
  fields: localFields ? JSON.parse(localFields) : [],
  error: null,
}

export const getFields = createAsyncThunk<Array<Field>, void, { rejectValue: SNTLError }>(
  'getFields',
  async () => await AppContainer.get<FieldServiceInterface>(TYPES.FieldServiceInterface).getFields(),
)

const fieldSlice = createSlice({
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFields.pending, (state: FieldState) => {
        state.loading = true
        return state
      })
      .addCase(getFields.fulfilled, (state: FieldState, action) => {
        // Add user to the state array
        state.fields = action.payload
        state.loading = false
        return state
      })
      .addCase(getFields.rejected, (state: FieldState, action) => {
        // Add user to the state array
        state.error = action.payload
        state.loading = false
        return state
      })
  },
  name: 'fields',
  reducers: {
    nop: (state) => state,
  },
})

export const fieldReducer = fieldSlice.reducer
