import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppContainer } from '../../helpers/inversify.config'
import { TYPES } from '../../helpers/types'
import type { SNTLError } from './AppServiceSlice'
import type { TeamService } from '../../services/TeamService'
import { Team } from '../../models/Team'

export interface TeamState {
  loading?: boolean | null
  teams?: Array<Team>
  error?: SNTLError | null
}
const initialState: TeamState = {
  loading: null,
  teams: [],
  error: null,
}

export const getTeams = createAsyncThunk<Array<Team>, void, { rejectValue: SNTLError }>(
  'getTeams',
  async () => await AppContainer.get<TeamService>(TYPES.TeamService).getTeams(),
)

const teamSlice = createSlice({
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.pending, (state: TeamState) => {
        state.loading = true
        return state
      })
      .addCase(getTeams.fulfilled, (state: TeamState, action) => {
        // Add user to the state array
        state.teams = action.payload
        state.loading = false
        return state
      })
      .addCase(getTeams.rejected, (state: TeamState, action) => {
        // Add user to the state array
        state.error = action.payload
        state.loading = false
        return state
      })
  },
  name: 'teams',
  reducers: {
    nop: (state) => state,
  },
})

export const teamReducer = teamSlice.reducer
