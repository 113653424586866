
export function deviceType() {
  const PRO = import.meta.env.VITE_APP_PROFILE
  return {
    isMobile: PRO == 'mock' ? true : navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i),
  }
}

export function deviceOsType() {
  return {
    isIOS: navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i) ? true : false,
  }
}