import React from 'react'
import { type CameraProps, useCamera } from '../hooks/useCamera'

/**
 * HOC that adds an `isVisible` prop that stops a component from rendering if
 * `isVisible===false`
 * @param WrappedComponent component to be selectively hidden
 * @returns null if `isVisible` is false
 */
export function withCameraPermission<P>(WrappedComponent: React.ComponentType<P>) {
  const AppServiceControlled = (props: P & CameraProps) => {
    const { cameraStatus } = useCamera()
    return <WrappedComponent {...props} cameraStatus={cameraStatus} />
  }

  return AppServiceControlled
}
