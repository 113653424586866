import { injectable } from 'inversify'
import fields from '../mock-data/fields.json'
import { Field, FieldServiceInterface } from '../interface/FieldServiceInterface'

@injectable()
export class MockFieldService implements FieldServiceInterface {
  getFields(): Promise<Array<Field>> {
    return new Promise<Array<Field>>((resolve) => {
      setTimeout(() => {
        resolve(fields)
      }, 1500)
    })
  }
}
