import { Team } from '../models/Team'

export interface TeamService {
  getTeams(): Promise<Array<Team>>
}
import { injectable } from 'inversify'
import { FieldApi, TeamApi } from '../Apis/PROXClient'
import { Field, FieldServiceInterface } from '../interface/FieldServiceInterface'

@injectable()
export class FieldService implements FieldServiceInterface {
  getFields(): Promise<Array<Field>> {
    return FieldApi.getFields()
  }
}
