import { useEffect, useState } from 'react'
import { Locals } from '../helpers/Storage';
import { CameraTypes } from '../models/RootEnum';
export type FrameSize = {
  width: number
  height: number,
  originalWidth: number
  originalHeight: number,
  frameWidth: number,
  frameHeight: number,
}
export function useWindowSize() {
    const frameWidthHeightRatio = 3/2;
    const borderWidthInFrame = 0;
    // Initialize state with undefined width/height so server and client renders match
    const width =  window.innerWidth
    const height =  window.innerHeight
    const ratio = window.devicePixelRatio
    const originalWidth =  width * ratio
    const originalHeight =  height * ratio

    let isLanscape = width > height;

    let isFilterMode = localStorage.getItem(Locals.FUN_FACTOR) === 'filter' ? true : false;
    let frameHeight =  isFilterMode ? (height - 190) : (height - 200)
      let frameWidth =  frameHeight*2/3
  
      if(frameWidth > width){
        frameWidth = width
        frameHeight = frameWidth * 3/2
      }

    if(isLanscape){
      frameHeight =  height
      frameWidth =  frameHeight*3/2

      if(frameWidth > (width - 220)){
        frameWidth = width - 220;
        frameHeight = frameWidth * 2/3
      }
  
      // if(frameWidth > width){
      //   frameWidth = width
      //   frameHeight = frameWidth * 3/2
      // }
    }

  const [windowSize, setWindowSize] = useState<FrameSize>({width,
      height,
      originalWidth,
      originalHeight, frameWidth, frameHeight })

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
          
          let isFilterMode = localStorage.getItem(Locals.FUN_FACTOR) === 'filter' ? true : false;
          

            // Set window width/height to state
            const width =  window.innerWidth
            const height =  window.innerHeight

            let isLanscape = width > height;

            const ratio = window.devicePixelRatio
            const originalWidth =  window.innerWidth * ratio
            const originalHeight =  window.innerHeight * ratio

            let frameHeight =  isFilterMode ? (height - 190) : (height - 200)
            let frameWidth =  frameHeight*2/3
        
            if(frameWidth > width){
              frameWidth = width
              frameHeight = frameWidth * 3/2
            }
      
          if(isLanscape){
            frameHeight =  height
            frameWidth =  frameHeight*3/2

            if(frameWidth > (width - 220)){
              frameWidth = width - 220;
              frameHeight = frameWidth * 2/3
            }
        
            // if(frameWidth > width){
            //   frameWidth = width
            //   frameHeight = frameWidth * 3/2
            // }
          }

            // const frameWidth =  width - 2*borderWidthInFrame
            // const frameHeight = frameWidth * frameWidthHeightRatio
            setWindowSize({width,
                height,
                originalWidth,
                originalHeight, frameWidth, frameHeight })
        }
        // Add event listener
        window.addEventListener('resize', handleResize)
        // Call handler right away so state gets updated with initial window size
        handleResize()
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}
