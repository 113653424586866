import _ from 'lodash'
import { useEffect, useState } from 'react'

export enum CameraStatus {
  CHECKING,
  HAS_CAMERA,
  HAS_NO_CAMERA,
  EXCEPTION,
}
export enum CameraState {
  CHECKING,
  ACTIVE,
  INACTIVE,
  EXCEPTION
}

export function canAccessCamera(cameraStatus?: CameraStatus | null) {
  if (cameraStatus == null) {
    return CameraStatus.CHECKING
  }
  return _.includes([CameraStatus.HAS_NO_CAMERA, CameraStatus.CHECKING], cameraStatus)
}
export interface CameraProps {
  cameraStatus?: CameraStatus | CameraStatus.CHECKING
  cameraState?: CameraState | CameraState.INACTIVE
}
export function useCamera(): CameraProps {
  const [cameraStatus, setCameraStatus] = useState<CameraStatus>(CameraStatus.HAS_CAMERA)
  const [cameraState, setCameraState] = useState<CameraState>(CameraState.HAS_CAMERA)

  useEffect(() => {
  //   setTimeout(async () => {
  //     const devices = _.flatten(await navigator.mediaDevices.enumerateDevices())
  //     const hasCam = _.chain(devices)
  //       .reduce(
  //         (prev: boolean, current: MediaDeviceInfo) => prev || current.kind == 'videoinput',
  //         false,
  //       )
  //       .value()
  //     const hasCameraPermission = _.chain(devices)
  //       .filter((device) => device.kind == 'videoinput')
  //       .reduce((prev: boolean, current: MediaDeviceInfo) => prev || current.label != '', false)
  //       .value()
  //     setCameraStatus(hasCam ? CameraStatus.HAS_CAMERA : CameraStatus.HAS_NO_CAMERA)
  //     setCameraState(hasCameraPermission ? CameraState.ACTIVE : CameraState.INACTIVE)
  //   }, 1000)
  // 
  })
  return { cameraStatus, cameraState }
}
