import React from 'react'
import { Spinner } from '../components/common/Spinner'

interface AnimatedLogo {
  isAnimating: boolean | null
  label: string | null
  color: string | null
  logo?: string | undefined
}

export const SplashPage = ({ isAnimating, label, color, logo }: AnimatedLogo) => (
  <div className='main-wrapper bg_overlay'>
    <div className='wrapper-inner'>
      <div className='text-center'>
        <div className='logo_box'>
          {/* <img src={logo} alt='' className='loading_img' width={'80%'} height={'auto'} /> */}
          <Spinner animating={isAnimating} color={color} />
        </div>
        <h4 className='h2_title' style={color ? { color: color } : {}}>
          {label}
        </h4>
      </div>
    </div>
  </div>
)

export const MobileSupportOnly = ({
  color,
  text,
  logo,
}: {
  color: string
  text: string | null | undefined
  logo?: string | undefined
}) => (
  <div className='main-wrapper bg_overlay'>
    <div className='wrapper-inner'>
      <div className='text-center'>
        {/* <div className='logo_box'>
          <img src={logo} alt='' className='loading_img'  width={'80%'} height={'auto'} />
        </div> */}
        <img src='/images/hr-line.png' alt='' className='loading_img' width={100} height={'auto'} />
        <h4
          className='h2_title'
          style={{
            ...(color ? { color: color } : {}),
            marginTop: 0,
          }}
        >
          <img width={'17px'} src='/images/mobile-supported.png' />
          {'  ' + text}
        </h4>
      </div>
    </div>
  </div>
)
