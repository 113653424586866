import React from 'react'
import { Spinner } from './Spinner'

interface LoaderType {
  color?: string | null
  text?: string | null
  animating?: boolean | null
}

export const Loader = ({ color, text }: LoaderType) => (
  <div className='logo_box d-flex justify-content-center text-center'>
    <h4 className='h2_title' style={color ? { color: color } : {}}>
      {text}
    </h4>
    <Spinner animating={true} color={color ?? null} />
  </div>
)
