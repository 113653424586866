import axios, { type AxiosInstance, type AxiosResponse, type RawAxiosRequestConfig } from 'axios'
import { FetchRquest } from '../utils/FetchRequest'
import * as ApiContants from './ApiConstants'
import type { AppSettings } from '../models/AppSettings'
import type { Artist } from '../models/Artist'
import type { Combination } from '../models/Media'
import type { Register } from '../models/Register'
import type { ResponseWrapper } from '../models/ResponseWrapper'
import type { PageTranslations } from '../models/Translations'
import { Team } from '../models/Team'
import { Field } from '../interface/FieldServiceInterface'
import { Filter } from '../interface/FilterServiceInterface'

// axios.defaults.withCredentials = true;

function CreateClient(
  baseURL: string | undefined,
  headers: Record<string, string>,
  _getHeaders?: any,
): AxiosInstance {
  const axiosClient = axios.create({ baseURL: baseURL, headers: headers })
  axiosClient.interceptors.response.use(function (response) {
    return response
  })
  return axiosClient
}
const API_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Content-Transfer-Encoding': 'base64',
}

const GUESTCAM_API_HEADER = {...API_HEADER, 'api-token' : ApiContants.GUESTCAM_API_TOKEN}
class PROXClient {
  private static instance: PROXClient
  private static guestcamInstance: PROXClient
  private static cloudinaryInstance: PROXClient
  private apiClient: AxiosInstance
  private constructor(client: AxiosInstance) {
    this.apiClient = client
  }
  public static client(): PROXClient {
    if (!this.instance)
      this.instance = new PROXClient(
        CreateClient(ApiContants.BASE_URL, API_HEADER, async (headers: any) => headers),
      )
    return this.instance
  }

  public static guestcamClient(): PROXClient {
    if (!this.guestcamInstance)
      this.guestcamInstance = new PROXClient(
        CreateClient(ApiContants.GUESTCAM_BASE_URL, GUESTCAM_API_HEADER, async (headers: any) => headers),
      )
    return this.guestcamInstance
  }

  public static cloudinaryClient(): PROXClient {
    if (!this.cloudinaryInstance)
      this.cloudinaryInstance = new PROXClient(
        CreateClient(ApiContants.CLOUDINARY_BASE_URL, {}),
      )
    return this.cloudinaryInstance
  }

  public get<T>(path: string, params?: any): Promise<AxiosResponse<T>> {
    return this.apiClient.get<T>(path, { params: params })
  }
  public post<T>(
    path: string,
    data: any,
    config?: { headers: { 'Content-Type': string } },
  ): Promise<AxiosResponse<any>> {
    return this.apiClient.post<T>(path, data, config)
  }
  public put<T>(
    path: string,
    data: any,
    config?: RawAxiosRequestConfig,
  ): Promise<AxiosResponse<any>> {
    return this.apiClient.put<T>(path, data, config)
  }
  public delete(path: string, config?: RawAxiosRequestConfig): Promise<AxiosResponse<any>> {
    return this.apiClient.delete(path, config)
  }
}

export class AppSettingApi {
  static getSettings(): Promise<ResponseWrapper<AppSettings>> {
    return PROXClient.client()
      .get<ResponseWrapper<AppSettings>>('/app_settings')
      .then((r) => Promise.resolve(r.data))
  }

  static getTranslation(translation_url: string): Promise<PageTranslations> {
    return FetchRquest<PageTranslations>(translation_url, {
      // method: 'GET',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
  }
}
export class ArtistApi {
  static getArtist(query: string): Promise<ResponseWrapper<Array<Artist>>> {
    return PROXClient.client()
      .get<ResponseWrapper<Array<Artist>>>('/artists?'+query)
      .then((r) => Promise.resolve(r.data))
  }
}

export class MediaApi {
  static getMedias(query: string): Promise<ResponseWrapper<Array<Combination>>> {
    return PROXClient.client()
      .get<ResponseWrapper<Array<Combination>>>('/medias?'+query)
      .then((r) => Promise.resolve(r.data))
  }
}

export class TeamApi {
  static getTeams(): Promise<ResponseWrapper<Array<Team>>> {
    return PROXClient.client()
      .get<ResponseWrapper<Array<Team>>>('/teams')
      .then((r) => Promise.resolve(r.data))
  }
}

export class PostImageApi {
  static sendImage(payload: any): Promise<AxiosResponse<any>> {
    return PROXClient.guestcamClient().post(ApiContants.MEDIA_OBJECT_END_POINT, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
export class GetImageApi {
  static getImage(payload: any): Promise<AxiosResponse<any>> {
    return PROXClient.client().get(ApiContants.GET_END_POINT, payload)
  }
}
export class RegisterApi {
  static register(payload: Register): Promise<AxiosResponse<any>> {
    return PROXClient.guestcamClient().post(ApiContants.MAIL_RECIPIENT_END_POINT, payload)
  }
}

export class FieldApi {
  static getFields(): Promise<Array<Field>> {
    return PROXClient.guestcamClient()
      .get<Array<Field>>('/fields')
      .then((r) => Promise.resolve(r.data))
  }
}

export class FilterApi {
  static getFilters(): Promise<ResponseWrapper<Array<Filter>>> {
    return PROXClient.client()
      .get<ResponseWrapper<Array<Filter>>>('/filters')
      .then((r) => Promise.resolve(r.data))
  }
}

export class GuestcamApi {
  static getNameAndFolderForNewMedia(): Promise<AxiosResponse<any>> {
    return PROXClient.guestcamClient().get(ApiContants.NAME_AND_FOLDER_END_POINT)
  }

  static storeMediaObjectData(payload: object): Promise<AxiosResponse<any>> {
    return PROXClient.guestcamClient().post(ApiContants.MEDIA_OBJECT_DATA_END_POINT, payload)
  }
}

export class CloudinaryClient {
  static async uploadfile(file: File, folder: string, payload: object | undefined, metadata: string | undefined): Promise<AxiosResponse<any>> {
      const data = new FormData()
      const cloud_name = ApiContants.CLOUDINARY_CLOUD_NAME;
      data.append('file', file)
      data.append('upload_preset', ApiContants.CLOUDINARY_CLOUD_PRESET)
      data.append("cloud_name", cloud_name)
      data.append("folder", folder)
      data.append("resource_type", "raw")

      const action = file.type.includes("video") ? "video" : "image";
      let url = `/${action}/upload`;

      if (typeof metadata === "string") {
        data.append("context", metadata)
      }

      if (typeof payload === "object") {
          Object.keys(payload).forEach(key => {
              data.append(key, payload[key])
          });
      }

      console.log(ApiContants.CLOUDINARY_BASE_URL);
      return await PROXClient.cloudinaryClient().post(url, data)
      .then((r) => Promise.resolve(r.data));
  }
}