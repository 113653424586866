import { injectable } from 'inversify'
import { RegisterApi } from '../Apis/PROXClient'
import type { UserService } from './UserService'
import type { Register } from '../models/Register'

@injectable()
export class UserServiceImpl implements UserService {
  register(user: Register): Promise<Register> {
    return RegisterApi.register(user).then((data) => Promise.resolve(data.data!))
  }
}
