import React, { useState } from "react";
import { Link } from "react-router-dom";
const LogoHeader = () => {
    const app_local_settings = localStorage.getItem("AppSettings");
    const AppSettings = JSON.parse(app_local_settings);
    const [logoSrc, setLogoSrc] = useState(AppSettings?.main_logo);

    return (
        <div className={`company-logo-section  ${appSettings?.logo_position}`}>
            <Link to="/">
                <img
                    className="company-logo"
                    src={logoSrc}
                    onError={(e) => {
                        e.target.src = "/images/oneplus-white-log.png";
                    }}
                    alt="Logo"
                />
            </Link>
        </div>
    );
};

export default LogoHeader;
