const TYPES = {
  AppService: Symbol.for('AppService'),
  ArtistService: Symbol.for('ArtistService'),
  ArtistStorage: Symbol.for('ArtistStorage'),
  UserService: Symbol.for('UserService'),
  TeamService: Symbol.for('TeamService'),
  FieldServiceInterface: Symbol.for('FieldServiceInterface'),
  FilterServiceInterface: Symbol.for('FilterServiceInterface'),
}

export { TYPES }
