import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as ApiContants from "./../Apis/ApiConstants";

import { useTranslation } from "react-i18next";
import Fields from "../components/field/Fields";
import { Locals } from "../helpers/Storage";
import { withFields } from "../HOC/withFields";
import { Loader } from "../components/common/Loader";
import { GuestCampClient } from "@snayvik-tech-labs/guestcamp-api";
import { PageRoutes } from "../models/RootEnum";

GuestCampClient.init({
    base_url: ApiContants.GUESTCAM_CLIENT_API_URL,
    api_token: ApiContants.GUESTCAM_API_TOKEN,
    client_name: ApiContants.GUESTCAM_CLIENT_NAME,
    event: ApiContants.GUESTCAM_EVENT_NAME,
});

const Registration = () => {
    const navigate = useNavigate();
    const [fields, setFields] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        GuestCampClient.GetFields().then((fiels) => {
            setFields(fiels);
        });
    }, []);

    const Loading = <Loader text={t("registration.loading")} />;

    const handleSubmitForm = (replace = false) => {
        const selectedLocale = localStorage.getItem(Locals.SELECTED_LOCALE);
        if (selectedLocale) {
            navigate(
                PageRoutes.FlipGamePage.replace(":locale", selectedLocale)
            );
        }
    };

    useEffect(() => {
        let timeoutId;
        let idleTimeout = 10000; // 5 seconds

        function startTimeout() {
            timeoutId = setTimeout(function() {
                window.location.href = '/';
            }, idleTimeout);
        }

        function resetTimeout() {
            clearTimeout(timeoutId);
            startTimeout();
        }

        // Add event listeners to detect user interaction
        document.addEventListener('mousemove', resetTimeout);
        document.addEventListener('keydown', resetTimeout);
        document.addEventListener('scroll', resetTimeout);
        document.addEventListener('click', resetTimeout);

        startTimeout();

        return () => {
            clearTimeout(timeoutId);
            document.removeEventListener('mousemove', resetTimeout);
            document.removeEventListener('keydown', resetTimeout);
            document.removeEventListener('scroll', resetTimeout);
            document.removeEventListener('click', resetTimeout);
        }
    }, [])
    
    useEffect(() => {
        const registration_data = localStorage.getItem(
            Locals.REGISTRATION_DATA
        );
        const registration_data_expire_at = localStorage.getItem(
            Locals.REGISTRATION_DATA_EXPIRE_AT
        );
        if (registration_data) {
            const registration_data_parsed = JSON.parse(registration_data);
            if (
                registration_data_parsed &&
                registration_data_expire_at &&
                new Date().getTime() < Number(registration_data_expire_at)
            ) {
                handleSubmitForm(true);
            }
        }
    }, []);

    return (
        <section className="form-flex">
            <div className="container">
                <div className="form-inner-sec">
                    <div className="">
                        <div className="form-section">
                            {fields?.length === 0 ? (
                                Loading
                            ) : (
                                <Fields
                                    fields={fields ?? []}
                                    handleSubmitForm={handleSubmitForm}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default withFields(Registration);
