import { injectable } from 'inversify'
// import { setTimeout } from 'timers'
import appSettings from '../mock-data/appSettings.json'
import translations from '../mock-data/translations.json'
import type { AppService } from './AppService'
import type { AppSettings } from '../models/AppSettings'
import type { PageTranslations } from '../models/Translations'

@injectable()
export class MockAppService implements AppService {
  getAppSettings(): Promise<AppSettings> {
    return new Promise<AppSettings>((resolve) => {
      setTimeout(() => {
        resolve(appSettings)
      }, 500)
    })
  }

  getTranslations(_translation_url: string): Promise<PageTranslations> {
    return new Promise<PageTranslations>((resolve) => {
      setTimeout(() => {
        resolve(translations)
      }, 500)
    })
  }
}
