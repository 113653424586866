import { injectable } from 'inversify'
import type { UserService } from './UserService'
import type { Register } from '../models/Register'

@injectable()
export class MockUserService implements UserService {
  register(_user: Register): Promise<Register> {
    return new Promise<Register>((resolve) => {
      setTimeout(() => {
        resolve({
          name: 'Proximus',
          email: 'proximus@gmail.com',
          tel: '+31 445552323',
        })
      }, 1500)
    })
  }
}
