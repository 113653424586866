import { injectable } from 'inversify'
import artists from '../mock-data/artists.json'
import type { ArtistService } from './ArtistService'
import type { Artist } from '../models/Artist'

@injectable()
export class MockArtistService implements ArtistService {
  getArtists(): Promise<Array<Artist>> {
    return new Promise<Array<Artist>>((resolve) => {
      setTimeout(() => {
        resolve(artists)
      }, 1500)
    })
  }
}
