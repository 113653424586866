import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppContainer } from '../../helpers/inversify.config'
import { TYPES } from '../../helpers/types'
import type { SNTLError } from './AppServiceSlice'
import type { Register } from '../../models/Register'
import type { UserService } from '../../services/UserService'

export interface UserState {
  loading?: boolean | null
  user?: Register | null
  error?: SNTLError | null
}
const initialState: UserState = {
  loading: null,
  user: null,
  error: null,
}

export const register = createAsyncThunk<Register, Register, { rejectValue: SNTLError }>(
  'register',
  async (args) => await AppContainer.get<UserService>(TYPES.UserService).register(args),
)

const userSlice = createSlice({
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state: UserState) => {
        state.loading = true
        return state
      })
      .addCase(register.fulfilled, (state: UserState, action) => {
        // Add user to the state array
        state.user = action.payload
        state.loading = false
        return state
      })
      .addCase(register.rejected, (state: UserState, action) => {
        // Add user to the state array
        state.error = action.payload
        state.loading = false
        return state
      })
  },
  name: 'user',
  reducers: {
    nop: (state) => state,
  },
})

export const userReducer = userSlice.reducer
