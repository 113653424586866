import { injectable } from 'inversify'
import { ArtistApi } from '../Apis/PROXClient'
import type { ArtistService } from './ArtistService'
import type { Artist } from '../models/Artist'

@injectable()
export class ArtistServiceImpl implements ArtistService {
  getArtists(query: string): Promise<Array<Artist>> {
    return ArtistApi.getArtist(query).then((data) => Promise.resolve(data.data!))
  }
}
