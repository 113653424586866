import { injectable } from 'inversify'
import { type IStorage, Locals } from './Storage'
import type { ArtistModel } from '../HOC/withArtists'
import type { Artist } from '../models/Artist'

@injectable()
export class ArtistStorage implements ArtistModel {
  private readonly storage: IStorage

  public constructor() {
    this.storage = window.localStorage
  }
  private get(key: string): string | null {
    return this.storage.getItem(key)
  }
  private set(key: string, value: string): void {
    this.storage.setItem(key, value)
  }
  private clearItem(key: string): void {
    this.storage.removeItem(key)
  }

  private clearItems(keys: string[]): void {
    keys.forEach((key) => this.clearItem(key))
  }

  public saveSelectedArtist(artist: Artist): void {
    window?.localStorage?.setItem(Locals.ARTIST, JSON.stringify(artist))
    // this.set(Locals.ARTIST, JSON.stringify(artist))
  }

  public clearSelectedArtist(): void {
    window?.localStorage?.removeItem(Locals.ARTIST)
    // this.set(Locals.ARTIST, JSON.stringify(artist))
  }

  public getSelectedArtist(): Artist | null {
    const string = window?.localStorage?.getItem(Locals.ARTIST)
    if (string !== null && string != '') {
      return JSON.parse(string) as Artist
    }
    return null
  }
}
