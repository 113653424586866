import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppContainer } from '../../helpers/inversify.config'
import { TYPES } from '../../helpers/types'
import type { SNTLError } from './AppServiceSlice'
import type { TeamService } from '../../services/TeamService'
import { Team } from '../../models/Team'
import { Filter, FilterServiceInterface } from '../../interface/FilterServiceInterface'

export interface FilterState {
  loading?: boolean | null
  filters?: Array<Filter>
  error?: SNTLError | null
}
const initialState: FilterState = {
  loading: null,
  filters: [],
  error: null,
}

export const getFilters = createAsyncThunk<Array<Filter>, void, { rejectValue: SNTLError }>(
  'getFilters',
  async () => await AppContainer.get<FilterServiceInterface>(TYPES.FilterServiceInterface).getFilters(),
)

const filterSlice = createSlice({
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFilters.pending, (state: FilterState) => {
        state.loading = true
        return state
      })
      .addCase(getFilters.fulfilled, (state: FilterState, action) => {
        // Add user to the state array
        state.filters = action.payload
        state.loading = false
        return state
      })
      .addCase(getFilters.rejected, (state: FilterState, action) => {
        // Add user to the state array
        state.error = action.payload
        state.loading = false
        return state
      })
  },
  name: 'filters',
  reducers: {
    nop: (state) => state,
  },
})

export const filterReducer = filterSlice.reducer
