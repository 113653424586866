import { configureStore } from '@reduxjs/toolkit'
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { appServiceReducer } from './slices/AppServiceSlice'
import { artistsReducer } from './slices/ArtistSlice'
import { userReducer } from './slices/UserSlice'
import { teamReducer } from './slices/TeamSlice'
import { fieldReducer } from './slices/FieldSlice'
import { filterReducer } from './slices/FilterSlice'

export const store = configureStore({
  reducer: {
    appSettingsReducer: appServiceReducer,
    artistsReducer: artistsReducer,
    teamReducer: teamReducer,
    userReducer: userReducer,
    fieldReducer: fieldReducer,
    filterReducer: filterReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
type AppDispatchFunc = () => AppDispatch
export const useAppDispatch: AppDispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
