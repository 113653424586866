import { injectable } from 'inversify'
import filters from '../../mock-data/filters.json'
import { Filter, FilterServiceInterface } from '../../interface/FilterServiceInterface'

@injectable()
export class MockFilterService implements FilterServiceInterface {
  getFilters(): Promise<Array<Filter>> {
    return new Promise<Array<Filter>>((resolve) => {
      setTimeout(() => {
        resolve(filters)
      }, 1500)
    })
  }
}
