export enum Locals {
  ARTIST = 'Artist',
  FUN_FACTOR = 'FunFactor',
  TEAM_ID = 'TeamId',
  TEAM_NAME = 'TeamName',
  CAMERA_TYPE ='CameraType',
  HAND_TYPE ='HandType',
  REGISTRATION_DATA ='RegistrationData',
  REGISTRATION_DATA_EXPIRE_AT ='RegistrationDataExpireAt',
  SELECTED_LOCALE = 'SelectedLocale',
  SELECTED_MEDIA = 'SelectedMedia',
  MAIL_RECIPIENT_ID = 'MailRecipientId',
}

export interface IStorage {
  getItem(key: string): string | null
  setItem(key: string, value: string): void
  removeItem(key: string): void
}

export class Storage<T extends string> {
  private readonly storage: IStorage

  public constructor() {
    alert(window.localStorage)
    this.storage = window.localStorage
  }
  protected get(key: T): string | null {
    return this.storage.getItem(key)
  }
  protected set(key: T, value: string): void {
    this.storage.setItem(key, value)
  }
  protected clearItem(key: T): void {
    this.storage.removeItem(key)
  }

  protected clearItems(keys: T[]): void {
    keys.forEach((key) => this.clearItem(key))
  }
}
