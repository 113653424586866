// CountDown.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CountDown = ({ onCountdownEnd }) => {
    const [countdown, setCountdown] = useState(3);

const navigate = useNavigate();
const { t } = useTranslation();

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setTimeout(() => onCountdownEnd(), 1000);
        }
    }, [countdown, onCountdownEnd]);

    return (
        <div className="countdown-section">
            <div className="countdown-txt text-center">
                <h3 className="ready-txt">{ t('other.be_ready') }</h3>
                <h1 className="countdown">{countdown > 0 ? countdown : "GO"}</h1>
            </div>
        </div>
    );
};

export default CountDown;
