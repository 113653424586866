import React from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Layout from "./Layout";
import ChooseLanguage from "./ChooseLanguage";
import { PageRoutes } from "../models/RootEnum";
import "react-toastify/dist/ReactToastify.css";
import Registration from "./Registration";
import FlipGamePage from "./FlipGamePage";

export default function SNTLRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={PageRoutes.Root} element={<Layout />}>
                    <Route index element={<ChooseLanguage />} />
                    <Route path={PageRoutes.Language} element={<ChooseLanguage />} />
                    <Route path={PageRoutes.Registration} element={<Registration />} />
                    <Route path={PageRoutes.FlipGamePage}  element={<FlipGamePage />} />
                </Route>
            </Routes>
            <ToastContainer />
        </BrowserRouter>
    );
}
