import React, { useEffect } from 'react'
import { type CameraProps, CameraStatus, useCamera } from '../hooks/useCamera'
import {
  type AppServiceState,
  getAppSettings,
  getAppTranslations,
} from '../redux/slices/AppServiceSlice'
import { useAppDispatch, useAppSelector } from '../redux/store'
import type { PageTranslation } from '../models/Translations'

export interface TranslationState {
  translation?: PageTranslation
}
/**
 * HOC that adds an `AppServiceState` props to render app settings
 * @returns React.ComponentType
 */
export function withAppSettigs<P>(WrappedComponent: React.ComponentType<P>) {
  const AppServiceControlled = (props: P & AppServiceState & CameraProps) => {
    const { cameraStatus } = useCamera()
    const dispatch = useAppDispatch()

    const { settingLoading, translationLoading, translations, error, appSettings } = useAppSelector(
      (state) => state.appSettingsReducer,
    )
    useEffect(() => {
      const hasCamera = cameraStatus === CameraStatus.HAS_CAMERA
      const appSettingNeeded = appSettings === null && settingLoading == null
      if (hasCamera && appSettingNeeded) {
        dispatch(getAppSettings())
      }
    }, [dispatch, appSettings, settingLoading, cameraStatus])

    useEffect(() => {
      if (translations == null && appSettings !== null && settingLoading == false) {
        dispatch(getAppTranslations())
      }
    }, [dispatch, appSettings, settingLoading, translations])
    return (
      <WrappedComponent
        {...props}
        {...({
          settingLoading,
          translations,
          error,
          appSettings,
          translationLoading,
          translation: translations?.nl,
        } as AppServiceState & TranslationState)}
        cameraStatus={cameraStatus}
      />
    )
  }

  return AppServiceControlled
}
