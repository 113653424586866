import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LogoHeader from "../components/common/LogoHeader";
import { PageRoutes } from "../models/RootEnum";
import { Locals } from "../helpers/Storage";
import { useAppSelector } from "../redux/store";
import { useWindowSize } from "../hooks/useWidnowSize";
import { Link } from "react-router-dom";

const ChooseLanguage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const windowSizes = useWindowSize();

    const { appSettings } = useAppSelector((state) => state.appSettingsReducer);

    const HandlePressOnLanguage = (lng: string) => {
        localStorage.setItem(Locals.SELECTED_LOCALE, lng);
        i18n.changeLanguage(lng);
        navigate(PageRoutes.Registration.replace(":locale", lng));
    };

    useEffect(() => { 

    }, []);

    return (
        <>
            {appSettings?.languages.length > 1 ? (
                <section className="memory-home-page-section">
                    <div className="container">
                        <div className="choise-language-section">
                            <div className="text-center">
                                <div className="mb-2">
                                    <h3 className="heading text-white text-uppercase">{t('language.title')}</h3>
                                </div>
                            </div>
                            <div className="choise-language-div">
                                {appSettings?.languages?.map((language) => {
                                    return (
                                        <div className="ch-ln">
                                            <h2
                                                key={language?.locale}
                                                onClick={() =>
                                                    HandlePressOnLanguage(
                                                        language?.locale ?? "en"
                                                    )
                                                }
                                                className="language"
                                            >
                                                {language?.locale}
                                            </h2>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <Link to="/registration">
                    <section className="memory-home-page-section">
                        <div className="container">
                            <div className="choise-language-section">
                                <div className="text-center">
                                    <div className="mb-2">
                                        <h3 className="heading text-white text-uppercase">{t('language.title')}</h3>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <p className="head-content text-white">{t('language.sub_title')}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </Link>
            )}
        </>
        
    );
};

export default ChooseLanguage;
