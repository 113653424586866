import teams from '../mock-data/teams.json'
import { injectable } from 'inversify'
import { TeamService } from './TeamService'
import { Team } from '../models/Team'

@injectable()
export class MockTeamService implements TeamService {
  getTeams(): Promise<Array<Team>> {
    return new Promise<Array<Team>>((resolve) => {
      setTimeout(() => {
        resolve(teams)
      }, 1500)
    })
  }
}
