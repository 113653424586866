import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from 'i18next';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import 'react-lazy-load-image-component/src/effects/blur.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import nlTranslation from '../../../lang/nl.json';
import enTranslation from '../../../lang/en.json';
import frTranslation from '../../../lang/fr.json';
import { store } from './redux/store';
import { Locals } from './helpers/Storage';

i18n.init({
	interpolation: { escapeValue: false },
	lng: localStorage.getItem(Locals.SELECTED_LOCALE) ?? 'en',
	resources: {
		nl: {
			translation: nlTranslation,
		},
		en: {
			translation: enTranslation,
		},
		fr: {
			translation: frTranslation,
		},
		// Add more language resources as needed
	},
	fallbackLng: 'nl'
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<I18nextProvider i18n={i18n}>
				<App />
	 		</I18nextProvider>
	 	</Provider>
	 </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
