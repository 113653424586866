/* eslint-disable */
export const BASE_URL = import.meta.env.VITE_APP_BASE_API
export const GUESTCAM_CLIENT_API_URL = import.meta.env.VITE_GUESTCAM_CLIENT_API_URL
export const GUESTCAM_CLIENT_NAME = import.meta.env.VITE_GUESTCAM_CLIENT_NAME
export const GUESTCAM_EVENT_NAME = import.meta.env.VITE_GUESTCAM_EVENT_NAME
// Auth end point for api requests
export const UPLOAD_END_POINT = 'upload'
export const GET_END_POINT = 'artists'
export const IMAGE_STORAGE_PATH = import.meta.env.VITE_APP_IMAGE_PATH

export const PROFILE = import.meta.env.VITE_APP_PROFILE
export const GET_REGISTER_END_POINT = 'register'
export const IS_MOCK = PROFILE === 'mock'

export const MEDIA_OBJECT_END_POINT = 'media-objects';
export const MEDIA_OBJECT_DATA_END_POINT = 'media-objects/data';
export const NAME_AND_FOLDER_END_POINT = 'media-objects/name-folder';
export const MAIL_RECIPIENT_END_POINT = 'mail-recipient';

export const GUESTCAM_BASE_URL = import.meta.env.VITE_GUESTCAM_BASE_URL
export const GUESTCAM_API_TOKEN = import.meta.env.VITE_GUESTCAM_API_TOKEN

/**** Cloudinary */

// export const CLOUDINARY_BASE_URL = import.meta.env.VITE_CLOUDINARY_BASE_URL
// export const CLOUDINARY_CLOUD_NAME = import.meta.env.VITE_CLOUDINARY_CLOUD_NAME
// export const CLOUDINARY_CLOUD_PRESET = import.meta.env.VITE_CLOUDINARY_CLOUD_PRESET
// export const CLOUDINARY_OVERRLAY_PATH = import.meta.env.VITE_CLOUDINARY_OVERRLAY_PATH
