import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppContainer } from '../../helpers/inversify.config'
import { TYPES } from '../../helpers/types'
import type { AppSettings } from '../../models/AppSettings'
import type { PageTranslations } from '../../models/Translations'
import type { AppService } from '../../services/AppService'
import type { RootState } from '../store'

export interface AppServiceState {
	appSettings?: AppSettings | null
	translations?: PageTranslations | null
	settingLoading?: boolean | null
	translationLoading?: boolean | null
	error?: SNTLError | null
}

const initialState: AppServiceState = {
	appSettings: null,
	translations: null,
	settingLoading: null,
	translationLoading: null,
	error: null,
}

export interface SNTLError {
	message: string | null
	object?: any | null
	error?: Error | null
}

export const getAppSettings = createAsyncThunk<AppSettings, void, { rejectValue: SNTLError }>(
	'settings',
	async () => await AppContainer.get<AppService>(TYPES.AppService).getAppSettings(),
)

// AsyncThunkConfig
type AppTranslationResponse = PageTranslations
export const getAppTranslations = createAsyncThunk<
	AppTranslationResponse,
	void,
	{ state: RootState; rejectValue: SNTLError }
>('translations', async (_args, _thunkAPI) => {
	const translationUrl = _thunkAPI.getState().appSettingsReducer.appSettings?.translation_url
	// console.warn(_thunkAPI.getState().appSettingsReducer.appSettings)
	if (translationUrl === null) {
		return _thunkAPI.rejectWithValue({ message: 'translation_url should not be null' })
	} else {
		const pageTranslations = await AppContainer.get<AppService>(TYPES.AppService).getTranslations(
			translationUrl!,
		)
		return pageTranslations
	}
})

export const appServiceState = createSlice({
	initialState,
	name: 'appService',
	reducers: {
		nop: (state) => state,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAppSettings.pending, (state: AppServiceState) => {
				state.settingLoading = true
				return state
			})
			.addCase(getAppSettings.fulfilled, (state: AppServiceState, action) => {
				// Add user to the state array
				state.appSettings = action.payload
				state.settingLoading = false
				return state
			})
			.addCase(getAppSettings.rejected, (state: AppServiceState, action) => {
				// Add user to the state array
				state.error = action.payload
				state.settingLoading = false
				return state
			})
			.addCase(getAppTranslations.pending, (state: AppServiceState) => {
				state.translationLoading = true
				return state
			})
			.addCase(getAppTranslations.fulfilled, (state: AppServiceState, action) => {
				// Add user to the state array
				state.translations = action.payload
				state.translationLoading = false
				return state
			})
			.addCase(getAppTranslations.rejected, (state: AppServiceState, action) => {
				// Add user to the state array
				state.error = action.payload
				state.translationLoading = false
				return state
			})
	},
})

export const appServiceReducer = appServiceState.reducer
