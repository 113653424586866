import { injectable } from 'inversify'
import { FilterApi } from '../Apis/PROXClient'
import { Filter, FilterServiceInterface } from '../interface/FilterServiceInterface'

@injectable()
export class FilterService implements FilterServiceInterface {
    getFilters(): Promise<Array<Filter>> {
      return FilterApi.getFilters().then((data) => Promise.resolve(data.data!))
    }
  }
