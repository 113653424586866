import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppContainer } from '../../helpers/inversify.config'
import { TYPES } from '../../helpers/types'
import type { SNTLError } from './AppServiceSlice'
import type { Artist } from '../../models/Artist'
import type { ArtistService } from '../../services/ArtistService'

export interface ArtistState {
  loading?: boolean | null
  artists?: Array<Artist> | null
  error?: SNTLError | null
}
const initialState: ArtistState = {
  loading: null,
  artists: null,
  error: null,
}

export const getArtists = createAsyncThunk<Array<Artist>, string, { rejectValue: SNTLError }>(
  'getArtists',
  async (query: string) => await AppContainer.get<ArtistService>(TYPES.ArtistService).getArtists(query),
)

const artistSlice = createSlice({
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getArtists.pending, (state: ArtistState) => {
        state.loading = true
        return state
      })
      .addCase(getArtists.fulfilled, (state: ArtistState, action) => {
        // Add user to the state array
        state.artists = action.payload
        state.loading = false
        return state
      })
      .addCase(getArtists.rejected, (state: ArtistState, action) => {
        // Add user to the state array
        state.error = action.payload
        state.loading = false
        return state
      })
  },
  name: 'artists',
  reducers: {
    nop: (state) => state,
  },
})

export const artistsReducer = artistSlice.reducer
