import { injectable } from 'inversify'
import { TeamApi } from '../Apis/PROXClient'
import { TeamService } from './TeamService'
import { Team } from '../models/Team'

@injectable()
export class TeamServiceImpl implements TeamService {
  getTeams(): Promise<Array<Team>> {
    return TeamApi.getTeams().then((data) => Promise.resolve(data.data!))
  }
}
