// FlipGamePage.jsx
import React, { useEffect, useState } from "react";
import QuestionMark from "../components/icons/QuestionMark";
import CountDown from "../../components/CountDown";
import * as ApiContants from "./../Apis/ApiConstants";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import LogoHeader from "../components/common/LogoHeader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageRoutes } from "../models/RootEnum";

const shuffleArray = (array) => {
    let currentIndex = array.length,
        randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ];
    }
    return array;
};

const FlipGamePage = () => {
    const RegistrationData = localStorage.getItem("RegistrationData");
    const registerUser = JSON.parse(RegistrationData);
    const APP_BASE_URL = ApiContants.BASE_URL;
    const app_local_settings = localStorage.getItem("AppSettings");
    const LocalSettings = JSON.parse(app_local_settings);
    const [appSettings, setAppSettings] = useState(LocalSettings);

    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const time_in_sec = appSettings?.timer;
    const initalTime = appSettings?.timer * 1000;
    const TIME_PASSED_MULTIPLE = 10;
    const TURNS_MULTIPLE = 16;
    const STARTING_SCORE = 2000;

    const [countDown, setCountDown] = useState(true);
    const [images, setImages] = useState([]);
    const [data, setData] = useState([]);
    const [cardOne, setCardOne] = useState(null);
    const [cardTwo, setCardTwo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [matchedCard, setMatchedCard] = useState(0);
    const [timer, setTimer] = useState(0);
    const [stopTimer, setStopTimer] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [countdownTime, setCountdownTime] = useState(initalTime);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [failResponseModal, setFailResponseModal] = useState(false);
    const [modalPrice, setModalPrice] = useState(false);
    const [priceImageSrc, setPriceImageSrc] = useState();
    const [gameScore, setGameScore] = useState(0);
    const [userTurned, setUserTurned] = useState(0);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [quit, setQuit] = useState(false);

    // Convert time to min and second from milliseconds.
    const getTimeDifference = (countdownTime) => {
        const time = countdownTime;
        setCountdownTime(countdownTime);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const seconds = Math.floor((time / 1000) % 60);
        setMinutes(minutes);
        setSeconds(seconds);
    };

    //******* calculating score per second and flip *******//
    const getScore = (YOUR_TIME_PASSED, YOUR_NO_OF_TURNS) => {

        const total_time_passed_score = YOUR_TIME_PASSED * TIME_PASSED_MULTIPLE;
        const total_truns_score = YOUR_NO_OF_TURNS * TURNS_MULTIPLE;

        const TOTAL_SCORE = STARTING_SCORE - (total_time_passed_score + total_truns_score);
        return TOTAL_SCORE;
    }
 
    useEffect(() => {
        const time_pased = time_in_sec - (countdownTime/1000);
        const turn = userTurned;
        const final_score = getScore(time_pased, turn);
        // console.log(final_score);
        setGameScore(final_score);
        
    }, [countdownTime,  time_in_sec, userTurned]);


    //**** loast the  game ****/
    const gameLost = () => {
        const payload = new FormData();
        payload.append("mail_recepient_id", registerUser?.id);
        payload.append("email", registerUser?.email);
        payload.append("total_time", STARTING_SCORE);
        payload.append("score", gameScore);

        axios.post(`${APP_BASE_URL}loser`, payload)
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.error("Error submitting form:", error);
        });
    };

    const leadingZero = (num) => {
        return num < 10 && num > 0 ? "0" + num : num;
    };

    useEffect(() => {
        // Set remaining time to solve
        if (countDown === false && stopTimer == false) {
            getTimeDifference(countdownTime);
            const interval = setInterval(() => {
                getTimeDifference(countdownTime - 1000);
            }, 1000);

            if (countdownTime <= 0) {
                setStopTimer(true);
                setFailResponseModal(true);
                gameLost();
                
            }
            return () => clearInterval(interval);
        }
    }, [countdownTime, countDown, stopTimer]);

    useEffect(() => {
        axios
            .get(`${APP_BASE_URL}medias`)
            .then((res) => {
                setData(res?.data?.data[0]);
            })
            .catch((error) => {
                alert("Failed to load media to grid.   Error: " + error);
            });
    }, [APP_BASE_URL]);

    useEffect(() => {
        if (quit === true) {
            navigate(PageRoutes.Root);
        }
    }, [quit]);

    useEffect(() => {
        if (showResponseModal === true || failResponseModal === true) {
            const interval = setInterval(() => {
                navigate(PageRoutes.Root);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [showResponseModal, failResponseModal]);

    useEffect(() => {
        const shuffledData = shuffleArray([...data, ...data]);
        setImages(shuffledData);
    }, [data]);

    useEffect(() => {
        let interval;
        var currentTime = new Date().toLocaleTimeString("en-GB");
        if (gameStarted && matchedCard < 6) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        } else if (matchedCard === 6) {
            setTimeout(() => {
                clearInterval(interval);

                const payload = new FormData();
                payload.append("mail_recepient_id", registerUser?.id);
                payload.append("email", registerUser?.email);
                payload.append("time", currentTime);
                payload.append("zone", "UTC");
                payload.append("score", gameScore);
                payload.append("total_time", STARTING_SCORE);

                axios
                    .post(`${APP_BASE_URL}prize`, payload)
                    .then((response) => {
                        // Document.getElementById('#price_image').src(response?.data?.image);
                        clearInterval(interval);
                        setPriceImageSrc(response?.data?.data?.data?.image);
                        console.log('image src  ' +priceImageSrc);
                        setShowResponseModal(true);
                        setStopTimer(true);
                        reverseCards();
                        
                    })
                    .catch((error) => {
                        console.error("Error submitting form:", error);
                        alert(
                            "Failed to submit form. Please try again later.  Error :  " +
                                error.message
                        );
                    });

               
                // const shuffledData = shuffleArray([...data, ...data]);
                // setImages(shuffledData);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [gameStarted, matchedCard]);

    useEffect(() => {
        if (cardOne && cardTwo) {
            setLoading(true);
            let cardOneImg = cardOne.querySelector(".back-view img").src;
            let cardTwoImg = cardTwo.querySelector(".back-view img").src;
            matchCard(cardOneImg, cardTwoImg);
        }
    }, [cardOne, cardTwo]);

    const reverseCards = () => {
        setMatchedCard(0);
        setCardOne(null);
        setCardTwo(null);
        const cards = document.querySelectorAll(".card");
        cards.forEach((card) => card.classList.remove("flip"));
    };

    const matchCard = (img1, img2) => {
        if (img1 === img2) {
            setMatchedCard(matchedCard + 1);
            cardOne.classList.add("opened");
            cardTwo.classList.add("opened");
            setCardOne(null);
            setCardTwo(null);
        } else {
            setTimeout(() => {
                cardOne.classList.add("shake");
                cardTwo.classList.add("shake");
            }, 400);
            setTimeout(() => {
                // if (img1 !== img2) {
                cardOne.classList.remove("shake", "flip");
                cardTwo.classList.remove("shake", "flip");
                // }
                setCardOne(null);
                setCardTwo(null);
            }, 1200);
        }
        setLoading(false);
    };

    const flipCard = ({ target: clickedCard }) => {
        if (!gameStarted) setGameStarted(true);
        setUserTurned(userTurned + 1);
        if (clickedCard !== cardOne && !loading && !cardTwo) {
            clickedCard.classList.add("flip");
            if (!cardOne) {
                setCardOne(clickedCard);
            } else {
                setCardTwo(clickedCard);
            }
        }
    };

    return (
        <div className="wrapper brack-filter">
            <div className="container p-0">
                {countDown ? (
                    <CountDown onCountdownEnd={() => setCountDown(false)} />
                ) : (
                    <div className="inner-section">
                        <div className="flip-inner-content">
                        <div
                            className={`timer-section ${appSettings?.timer_position}`}
                        >
                            <div className="timer-div">
                                <p className="timer time-scr-txt">
                                    {t("other.timer")}:{" "}
                                    {leadingZero(minutes) +
                                        " : " +
                                        leadingZero(seconds)}
                                    "
                                </p>
                            </div>
                            <div className="score-div">
                                <p className="score time-scr-txt">
                                    {t("other.score")}: {gameScore}
                                </p>
                            </div>
                        </div>
                        <div className="logo-section"></div>
                        <ul className="cards">
                            {images.map((item, index) => (
                                <li
                                    key={index}
                                    className="card"
                                    onClick={flipCard}
                                >
                                    <div className="view front-view">
                                        {/* <QuestionMark /> */}
                                        <img
                                            className="img-fluid"
                                            src={appSettings?.second_logo}
                                            alt=""
                                        />
                                    </div>
                                    <div className="view back-view">
                                        <img src={item?.image} alt="" />
                                    </div>
                                </li>
                            ))}
                        </ul>
                        </div>
                    </div>
                )}
            </div>
            <Modal
                show={showResponseModal}
                onHide={() => setQuit(true)}
                centered
                size="md"
                scrollable
            >
                <Modal.Body>
                    <>
                        {priceImageSrc ? (
                            <div className="form-response-model">
                                <div className="gift-section text-center">
                                    <div className="gift-img-div glow-container">
                                        <img
                                            id="price_image"
                                            className="giftImg img-fluid glow-image"
                                            src={`/storage/${priceImageSrc}`}
                                            alt=""
                                        />

                                        {/* <img src="path/to/transparent-image.png" alt="Transparent Image" className="glow-image" /> */}
                                    </div>
                                    <div className="form-top-sec">
                                        <div className="brd">
                                            <h3 className="form-head">
                                                {t("winning.congrats")}
                                            </h3>
                                        </div>
                                        <button
                                            className="cong-btn"
                                            onClick={() => setQuit(true)}
                                        >
                                            Reset
                                            <span className="confirm-arrow">
                                                <svg
                                                    viewBox="0 0 32 32"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M22 9a1 1 0 0 0 0 1.42l4.6 4.6H3.06a1 1 0 1 0 0 2h23.52L22 21.59A1 1 0 0 0 22 23a1 1 0 0 0 1.41 0l6.36-6.36a.88.88 0 0 0 0-1.27L23.42 9A1 1 0 0 0 22 9Z"
                                                        data-name="Layer 2"
                                                        fill="#ffffff"
                                                        className="fill-000000"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </button>
                                </div>
                            </div>
                        </div>
                        ) : (
                            <div className="form-response-model">
                                <div className="">
                                    <div className="congrates-sec">
                                        <div className="cong-head-sec">
                                            <h2 className="cong-heading">
                                                {t("completed.damage")}
                                            </h2>
                                        </div>
                                        <div className="cong-content-sec">
                                            <div className="cong-btn-sec">
                                                <button
                                                    className="cong-btn"
                                                    onClick={() =>
                                                        setQuit(true)
                                                    }
                                                >
                                                    Reset
                                                    <span className="confirm-arrow">
                                                        <svg
                                                            viewBox="0 0 32 32"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M22 9a1 1 0 0 0 0 1.42l4.6 4.6H3.06a1 1 0 1 0 0 2h23.52L22 21.59A1 1 0 0 0 22 23a1 1 0 0 0 1.41 0l6.36-6.36a.88.88 0 0 0 0-1.27L23.42 9A1 1 0 0 0 22 9Z"
                                                                data-name="Layer 2"
                                                                fill="#ffffff"
                                                                className="fill-000000"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                </Modal.Body>
            </Modal>
            {/* GAME FAIL MESSAGE MODAL */}
            <Modal
                show={failResponseModal}
                onHide={() => setQuit(true)}
                centered
                size="md"
                scrollable
            >
                <Modal.Body>
                    <div className="form-response-model">
                        <div className="">
                            <div className="congrates-sec">
                                <div className="cong-head-sec">
                                    <h2 className="cong-heading">
                                        {t("completed.damage")}
                                    </h2>
                                </div>
                                <div className="cong-content-sec">
                                    <div className="cong-btn-sec">
                                        <button
                                            className="cong-btn"
                                            onClick={() => setQuit(true)}
                                        >
                                            Reset
                                            <span className="confirm-arrow">
                                                <svg
                                                    viewBox="0 0 32 32"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M22 9a1 1 0 0 0 0 1.42l4.6 4.6H3.06a1 1 0 1 0 0 2h23.52L22 21.59A1 1 0 0 0 22 23a1 1 0 0 0 1.41 0l6.36-6.36a.88.88 0 0 0 0-1.27L23.42 9A1 1 0 0 0 22 9Z"
                                                        data-name="Layer 2"
                                                        fill="#ffffff"
                                                        className="fill-000000"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FlipGamePage;
