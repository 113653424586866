import { IMAGE_STORAGE_PATH, PROFILE } from '../Apis/ApiConstants'
import { StringKeyValueContainer } from '../models/StringKeyValueContainer'

export class Utils {
  /**
   * Convert BASE64 to BLOB
   * @param base64Image Pass Base64 image data to convert into the BLOB
   */
  static convertBase64ToBlob(base64Image: string) {
    // Split into two parts
    const parts = base64Image.split(';base64,')

    // Hold the content type
    const imageType = parts[0].split(':')[1]

    // Decode Base64 string
    const decodedData = atob(parts[1])

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }

  static getImagePath(path: string | null | undefined, isIOS: boolean = false, isLandscape: boolean = false): string {
    if (PROFILE == 'mock') {
      return path ?? ''
    }

    if (path?.startsWith("https://res.cloudinary.com")) {
      var newPath = path;
      if (!isIOS) {
        if (isLandscape) {
          newPath = path.replace("video/upload/", 'video/upload/w_750,h_500/');
        } else {
          newPath = path.replace("video/upload/", 'video/upload/w_500,h_750/');
        }
      }
      return newPath
    }
    return IMAGE_STORAGE_PATH + (path ?? '')
  }

  static getFieldName(name: StringKeyValueContainer | string, locale: string): string {
    if (typeof name === 'string') {
      return name;
    }

    return name[locale] ?? ""
  }
}
